import React from 'react'
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

import Sunglassbanner from './layout/sunglassBanner';
import Sunglasslist from './layout/sunglassList';
import { Helmet } from "react-helmet";

import ProductListCarousel from './layout/carouselslide1';
import LenskartContent from './content/LenskartCarouselContent'


const Glasses = () => {
  return (
        <>
    <Navbar />
    <div className='my-5'>
        <div className='mb-10'>

        

          <Helmet>
          <title>Shop Stylish Sunglasses | TrendZ - Trendy Eyewear Collections</title>
          <meta name="description" contents="Discover the latest in stylish sunglasses at TrendZ (trendz.blog/glasses). Shop trendy eyewear collections featuring premium quality, modern designs, and UV protection. Find the perfect pair to complement your style. Upgrade your look with TrendZ sunglasses today!, stylish sunglasses, trendy eyewear, TrendZ sunglasses, fashion sunglasses, UV protection sunglasses, designer sunglasses, best sunglasses, latest sunglasses, sunglasses for men, sunglasses for women, trendy glasses, affordable sunglasses, high-quality sunglasses, online sunglasses shop, sunglasses collections" />
          <meta name="keywords" keywords="stylish sunglasses, trendy eyewear, TrendZ sunglasses, fashion sunglasses, UV protection sunglasses, designer sunglasses, best sunglasses, latest sunglasses, sunglasses for men, sunglasses for women, trendy glasses, affordable sunglasses, high-quality sunglasses, online sunglasses shop, sunglasses collections" />
        </Helmet>

        </div>

        <div className='flex w-1/3 gap-3  mx-5 lg:mx-24 my-5'>

            <img className=' px-5 border bg-emerald-400 ' src='https://1000logos.net/wp-content/uploads/2022/10/Lenskart-Logo.png' alt='' />
            <img className='w-3/4 border bg-black px-5 ' src='https://www.easemydeal.com/assets/new/image/BrandLogos/titan_eye_plus.png' alt='' />
            <img className='px-5  ' src='https://images-na.ssl-images-amazon.com/images/S/gladiator-image-upload-prod/4/A21TJRUUN4KGV/3f14660242e5f219c82304fbecf50e71.jpg' alt='' />

        
        </div>
        
      
            <ProductListCarousel data={LenskartContent}  />
       



        <div>

          <Sunglassbanner />


        </div>

        <div>
          <h1>   </h1>
          <div className='border bg-black rounded-xl mx-5 my-8 lg:mx-10 lg:my-10'>
            <Sunglasslist />
          </div>
        </div>





         <div className='mt-10'>


        <Footer />


        </div>


    </div>

    </>
  )
}

export default Glasses