const data =[
    {
        image:'https://m.media-amazon.com/images/I/5109sLDpkvL._SX679_.jpg',
        title: `January`,
        country: 'Grocery',
        description:`Tata Tea Gold 1kg`,
        mrprate:`Mrp600`,
        traderate:`₹380`,
        code:`B00GLDJ9WA`,
        rating:`4.7`,
        keyword:`product trade in 400 but do save & subscribe of 5% available in 380 *must read how to purchase* `,
        blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
     },
     {
        image:'https://m.media-amazon.com/images/I/610vVXE-yEL._SX679_.jpg',
        title: `January`,
        country: 'Beauty',
        description:`Lotus Herbals Safe Sun Sunscreen Cream `,
        mrprate:`Mrp199`,
        traderate:`₹98`,
        code:`B006LXDCGE`,
        rating:`4.1`,
        keyword:`One Of the Best rate Ever in January month`,
        blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
     },
     {
        image:'https://m.media-amazon.com/images/I/51Lrjc+RUkL._SX679_.jpg',
        title: `January`,
        country: 'Beauty',
        description:`Boroplus Antiseptic Cream `,
        mrprate:`Mrp210`,
        traderate:`₹132`,
        code:`B07F7DHFS7`,
        rating:`4.5`,
        keyword:`one of the best moisturizer cream of all time, Can be applied in case of cuts, scratches, minor wounds and burns, unisex`,
        blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
     },
     {
      image:'https://m.media-amazon.com/images/I/71eMpfqzSCL._SX679_PIbundle-5,TopRight,0,0_SX679SY447SH20_.jpg',
      title: `January`,
      country: 'BabyProduct',
      description:`Himalaya Baby Gift Pack`,
      mrprate:`Mrp255`,
      traderate:`₹142`,
      code:`B00CARBF6E`,
      rating:`4.4`,
      keyword:`Complete Baby Care in One Pack, Safe and Natural Ingredients, Ideal for Gifting`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
   {
      image:'https://m.media-amazon.com/images/I/41Ec8VrQpDL._SX300_SY300_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'Health&PersonalCare',
      description:`Perfora Teeth Whitening Toothpaste For Stain Removal `,
      mrprate:`Mrp299`,
      traderate:`₹206`,
      code:`B0063BN5VQ`,
      rating:`4.3`,
      keyword:`Gentle and Effective Whitening, Safe and Natural Formulation, All-in-One Oral Care`,
      blink:`https://amzn.urlvia.com/492s5m`,
   },

   {
      image:'https://m.media-amazon.com/images/I/31bic-op7CL._SX300_SY300_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'BabyProduct',
      description:`Himalaya Gentle Baby Shampoo (200ml)`,
      mrprate:`Mrp220`,
      traderate:`₹100`,
      code:`B07MBFFRB1`,
      rating:`4.3`,
      keyword:`Tear-Free and Mild Formula, Natural Ingredients for Nourishment, Dermatologically Tested and Safe`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/71VBuPUbXcL._SX679_PIbundle-6,TopRight,0,0_AA679SH20_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Savlon Moisturizing Glycerin Soap Bar With Germ Protection`,
      mrprate:`Mrp375`,
      traderate:`₹143`,
      code:`B086H6LMBM`,
      rating:`4.3`,
      keyword:`Dual Benefits of Moisturization and Germ Protection, Suitable for All Skin Types, Value Pack for Long-Lasting Freshness`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/51KCBlo6uEL._SX679_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Dabur Gulabari Rose Glow Face Cleanser - 100ml`,
      mrprate:`Mrp95`,
      traderate:`₹50`,
      code:`B006Y11ES6`,
      rating:`4.3`,
      keyword:`Natural Rose Goodness for a Radiant Glow, Gentle on All Skin Types, Complete Skin Care in One Step`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/41qlpafXP1L._SX679_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Garnier Men, Face Wash, Brightening & Anti-Pollution, TurboBright Double Action, 100 g`,
      mrprate:`Mrp269`,
      traderate:`₹138`,
      code:`B00V4L6JC2`,
      rating:`4.3`,
      keyword:`Dual Action for Brightening and Anti-Pollution, Designed for Men's Skin, Quick and Effective Results`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/61Vp0-XHx2L._SX679_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Garnier Skin Naturals, Facewash, Cleansing and Brightening, Bright Complete, 100 g`,
      mrprate:`Mrp209`,
      traderate:`₹116`,
      code:`B006LX9K92`,
      rating:`4.2`,
      keyword:`Brightening with Vitamin C, Gentle Yet Powerful Cleansing, Fights Tan and Uneven Skin Tone`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/71ZgUJQOvYL._SX679_.jpg',
      title: `January`,
      country: 'Amazon Fashion',
      description:`IIK COLLECTION Watches for Women Round Studded Dial |Day and Date Quartz Movemnet |Long Battery Life|Stainless Steel Bracelet Chain Strap|Double Lock Clasp Safety Watches for Girls`,
      mrprate:`Mrp1499`,
      traderate:`₹399`,
      code:`B07YC71Y12`,
      rating:`3.8`,
      keyword:`Elegant and Stylish Design, Durable and Reliable Build, Functional and Convenient`,
      blink:`https://amzn.urlvia.com/YMPfZr`,
   },

   {
      image:'https://m.media-amazon.com/images/I/51GnlKdb3GL._SX679_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Dove Hair Fall Rescue Shampoo For Weak Hair, 1 Ltr`,
      mrprate:`Mrp428`,
      traderate:`₹399`,
      code:`B07H9T25WH`,
      rating:`4.3`,
      keyword:`Reduces Hair Fall Effectively, Gentle Care for Everyday Use, Large Size for Long-Term Value`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/612zhIOKqFL._SX679_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Boroplus Body Lotion Provides 24Hrs Moisturisation 100% Ayurvedic Lotion, Vitamin E For Dry And Normal Skin With Goodness Of Dhoodh, Badam & Kesar, 400Ml`,
      mrprate:`Mrp415`,
      traderate:`₹124`,
      code:`B07VV5R3FB`,
      rating:`4.2`,
      keyword:`24-Hour Deep Moisturization, 100% Ayurvedic and Safe, Multi-Benefit Formula`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/713uX16U3hL._SX679_.jpg',
      title: `January`,
      country: 'Health&personalCare',
      description:`Colgate Strong Teeth, 700G, India S No: 1 Toothpaste Brand, Calcium-Boost Tooth Paste For 2X Stronger Teeth, Whitens Teeth, Fresh Breath & Prevents Cavities (Combo Pack Offer)..`,
      mrprate:`Mrp480`,
      traderate:`₹289`,
      code:`B09MYGG3J9`,
      rating:`4.4`,
      keyword:`2X Stronger Teeth with Calcium Boost, Comprehensive Oral Care, India’s No. 1 Trusted Brand`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/71gKSQpWLcL._SX679_.jpg',
      title: `January`,
      country: 'Health&personalCare',
      description:`Outsup Keto Fat Burner 60 Capsules 1000MG, Weight Loss Supplement With Garcinia Cambogia, Green Coffee Beans ,Green Tea Extract & L-Carnitine`,
      mrprate:`Mrp1299`,
      traderate:`₹499`,
      code:`B0D9HV8DMZ`,
      rating:`4.5`,
      keyword:`Powerful Fat-Burning Formula, Enhanced Energy and Focus, Natural and Safe Weight Management`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
   {
      image:'https://m.media-amazon.com/images/I/51HZd60Z8JL._SX300_SY300_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'Health&personalCare',
      description:`Colgate MaxFresh Toothpaste, Red Gel Tooth Paste with Menthol for Super Fresh Breath, 600g, 150g X 4 (Spicy Fresh) (Combo Pack Offer)`,
      mrprate:`Mrp544`,
      traderate:`₹261`,
      code:`B01NAZBI08`,
      rating:`4.4`,
      keyword:`Super Fresh Breath with Menthol Boost, Powerful Cleansing Action, Convenient Combo Pack`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
   {
      image:'https://m.media-amazon.com/images/I/41AYQfZwDnL._SY445_SX342_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'Lawn&Garden',
      description:`Hit Crawling Insect Killer Cockroach Killer Spray (400Ml) | Instant Kill | Deep-Reach Nozzle | Fresh Fragrance..`,
      mrprate:`Mrp225`,
      traderate:`₹169`,
      code:`B00XORDU9E`,
      rating:`4.3`,
      keyword:`Instant and Effective Kill, Deep-Reach Nozzle, Fresh Fragranc`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
   {
      image:'https://m.media-amazon.com/images/I/61J8kYjIuhL._SX679_.jpg',
      title: `January`,
      country: 'Grocery',
      description:`Cadbury Hot Chocolate Drink Powder Mix, 200 g`,
      mrprate:`Mrp230`,
      traderate:`₹166`,
      code:`B071YCHCN1`,
      rating:`4.3`,
      keyword:`Rich and Creamy Indulgence, Easy to Prepar, Perfect for All Ages`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/711hgbm4rmL._SX679_.jpg',
      title: `January`,
      country: 'Baby',
      description:`Himalaya Baby Cream, Face Moisturizer & Day Cream, For Dry Skin, 200ml`,
      mrprate:`Mrp280`,
      traderate:`₹132`,
      code:`B00QGEN6C8`,
      rating:`4.4`,
      keyword:`Soft on Skin, Safe for Babies, Gentle and Nourishing`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },


{
      image:'https://m.media-amazon.com/images/I/51FTaE92esL._SX522_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Garnier Skin Naturals, Anti-Ageing Cream, Moisturizing, Forming & Smoothing, Wrinkle Lift, 40 gs`,
      mrprate:`Mrp329`,
      traderate:`₹187`,
      code:`B00791D32U`,
      rating:`4.2`,
      keyword:`Radiant Skin, Fights Wrinkles, Moisturizing Properties`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/41fzL9D5suL._SX522_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Dove Intense Repair Hair Conditioner, For Damaged And Frizzy Hair, 175 ml`,
      mrprate:`Mrp210`,
      traderate:`₹137`,
      code:`B07H9Y6YHX`,
      rating:`4.3`,
      keyword:`Detangling Conditioner, Damage Repair, Smoothers Frizzy Hair`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/71FJJEM2VgL._SX522_.jpg',
      title: `January`,
      country: 'Electronics',
      description:`amazon basics AA Performance Alkaline Non-Rechargeable Batteries (8-Pack)`,
      mrprate:`Mrp445`,
      traderate:`₹179`,
      code:`B00O869KJE`,
      rating:`4.2`,
      keyword:`Alkaline Batteries, Anti-Corrosion Components, Prevents Surface Terminal Resistance `,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/61NFh1DqTiL._SX522_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`BLUSHLIN 10% Tranexamic acid serum for melasma, pigmentation, acne scars or inflammation (PIH/PIE) with Niacinamide & Vitamin C`,
      mrprate:`Mrp399`,
      traderate:`₹299`,
      code:`B0C5XZWY69`,
      rating:`4.1`,
      keyword:`Reduces Hyperpigmentation, Fregrance-Free, Moisturizes and Brightens Skin`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/61lkTAMHR0L._SX679_.jpg',
      title: `January`,
      country: 'Health',
      description:`BLUSHLIN Collagen+Antioxidants Supplement-3 Sachets of Marine Collagen Powder for Men & Women. Enhances Skin Hydration, Minimizes Skin Damage, Promotes Healthy Skin, Hair, and Nails. Cranberry Flavor.`,
      mrprate:`Mrp499`,
      traderate:`₹199`,
      code:`B0D6SN2LX7`,
      rating:`5.0`,
      keyword:`Boosts Collagen And Skin Elasticity, Hydrates And Brightens Skin, Filled With Antioxidants`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/61a9hjbvYFL._SX679_.jpg',
      title: `January`,
      country: 'Health',
      description:`B'VERGE® L-Carnitine + Good Bug Probiotics Supplement | Weight Loss & Gut Cleanse for Women's Good Health`,
      mrprate:`Mrp300`,
      traderate:`₹199`,
      code:`B0DJHFYR6K`,
      rating:`5.0`,
      keyword:`Aids Weight Loss, Filled With Probiotics, Promotes Gut Cleanse`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/41YycyrJ-gL._SX522_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Luxiora Luxe Crème Matte Lipstick - Velvet Finish, Long-Lasting Hydration, Rich Pigments for All-Day Wear (DOMINANCE RUBY, 4.2gm)`,
      mrprate:`Mrp799`,
      traderate:`₹350`,
      code:`B0DQVCMGNB`,
      rating:`5.0`,
      keyword:`Lightweight and Smooth, Long-lasting Stay, Vitamin E Hydration`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/41EPOZOgc2L._SX522_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`Luxiora Luxe Liquid Lipstick - Velvet Matte Finish for All-Day Wear, Hydrating Formula with Rich Pigments for a Bold, Sophisticated Look in Stunning Shades. (Red Rebel, 2ml)`,
      mrprate:`Mrp799`,
      traderate:`₹350`,
      code:`B0DQY2GPQK`,
      rating:`5.0`,
      keyword:`SPF 15, Provides Hydration, 18 Hours Stay`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/61x1Zonlr1L._SX679_.jpg',
      title: `January`,
      country: 'Personal Care',
      description:`Surf Excel Detergent Bar|| Laundry Detergent Bar For Clothes|| Removes Tough Stains|| 4x200 g`,
      mrprate:`Mrp125`,
      traderate:`₹125`,
      code:`B00I2DJNVO`,
      rating:`4.4`,
      keyword:`Stain Eraser, Gentle on Clothes And Hands, Premium Detergent Bar `,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/61Mohr7ru3L._SX679_.jpg',
      title: `January`,
      country: 'Personal Care',
      description:`Vim Antismell Dishwash Bar with Pudina|| 190g (Buy 4 Get 1 Free)`,
      mrprate:`Mrp130`,
      traderate:`₹122`,
      code:`B079H72M8L`,
      rating:`4.4`,
      keyword:`Pudina Extract, Plastic Coating, Fights Food Smells `,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/81JD6yH-VyL._SY741_.jpg',
      title: `January`,
      country: 'Grocery',
      description:`Kellogg's Muesli 0% Added Sugar 500 G | 12-In-1 Power Breakfast | India's No. 1 Muesli`,
      mrprate:`Mrp395`,
      traderate:`₹395`,
      code:`B083JNSV13`,
      rating:`4.4`,
      keyword:`No Added Sugar, Benefits of 12 Multigrain, Filled with Fibre and Vitamin B`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/41MggvYvScL._SX300_SY300_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'Baby',
      description:`Pampers Active Baby Taped Diapers, Medium size diapers, (6-11kg) 90 count, taped style custom fit`,
      mrprate:`Mrp1699`,
      traderate:`₹1300`,
      code:`B077HRCSXR`,
      rating:`4.4`,
      keyword:`Stretchable Soft Material, 360 Degree Cottony Softness, 12 Hours Absorption`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/41JWJmdf81L._SX300_SY300_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'Baby',
      description:`Pampers Active Baby Diapers, Extra Large (56 Count)`,
      mrprate:`Mrp1699`,
      traderate:`₹1300`,
      code:`B077HXK5ZZ`,
      rating:`4.4`,
      keyword:`Stretchable Soft Material, 360 Degree Cottony Softness, 12 Hours Absorption`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
   
{
      image:'https://m.media-amazon.com/images/I/718WZXA-TmL._SX679_.jpg',
      title: `January`,
      country: 'Baby',
      description:`Pampers Premium Care Pants, Small size baby diapers (S), 140 Count, Softest ever Pampers pants`,
      mrprate:`Mrp2998`,
      traderate:`₹1400`,
      code:`B082LF76Q4`,
      rating:`4.4`,
      keyword:`Aloe Vera Lotion, 12 hours Leak Lock, Enables Skin Breathing`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
{
      image:'https://m.media-amazon.com/images/I/61muVCIy-uL._SX679_.jpg',
      title: `January`,
      country: 'Electronics',
      description:`OnePlus 13R | Smarter with OnePlus AI (12GB RAM, 256GB Storage Nebula Noir)`,
      mrprate:`Mrp44999`,
      traderate:`₹43000`,
      code:`B0DPS62DYH`,
      rating:`4.2`,
      keyword:` Snapdragon 8 Gen 3 Flagship, Oxygen OS 15, 6000mAh Battery, SONY LYT-700 50MP Camera`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },

   {
      image:'https://m.media-amazon.com/images/I/61muVCIy-uL._SX679_.jpg',
      title: `January`,
      country: 'Electronics',
      description:`OnePlus 13R | Smarter with OnePlus AI (12GB RAM, 256GB Storage Nebula Noir)`,
      mrprate:`Mrp44999`,
      traderate:`₹43000`,
      code:`B0DPS62DYH`,
      rating:`4.2`,
      keyword:` Snapdragon 8 Gen 3 Flagship, Oxygen OS 15, 6000mAh Battery, SONY LYT-700 50MP Camera`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },
   {
      image:'https://m.media-amazon.com/images/I/51pceTEI0ZL._SX300_SY300_QL70_FMwebp_.jpg',
      title: `January`,
      country: 'Beauty',
      description:`BLUSHLIN 10% Tranexamic acid serum for melasma, pigmentation, acne scars or inflammation (PIH/PIE) with Niacinamide & Vitamin C`,
      mrprate:`Mrp399`,
      traderate:`₹299`,
      code:`B0C5XZWY69`,
      rating:`4.1`,
      keyword:`Targets Stubborn Pigmentation and Scars, Multi-Active Skin Repair,  Gentle and Suitable for All Skin Types`,
      blink:`https://amzn.urlvia.com/6oCEIS`,
   },

   {
      image:'https://m.media-amazon.com/images/I/61a9hjbvYFL._SX679_.jpg',
      title: `January`,
      country: 'Health&PersonalCare',
      description:`B'VERGE® L-Carnitine + Good Bug Probiotics Supplement | Weight Loss & Gut Cleanse for Women's Good Health`,
      mrprate:`Mrp300`,
      traderate:`₹199`,
      code:`B0DJHFYR6K`,
      rating:`5.0`,
      keyword:`Dual Action for Weight Loss and Gut Health, Designed for Women’s Wellness, Natural and Safe Formula`,
      blink:`https://amzn.urlvia.com/elGFDI`,
   },

   {
      image:'https://m.media-amazon.com/images/I/71bRKlPaajL._SX679_.jpg',
      title: `January`,
      country: 'Car&Motorbike',
      description:`Shakti Technology S3 High Pressure Car Washer Machine 1800 Watts and Pressure 120 Bar for Cleaning Car, Bike & Home (S3)`,
      mrprate:`Mrp8999`,
      traderate:`₹4399`,
      code:`B0BBWJFK5C`,
      rating:`4.4`,
      keyword:`Powerful Cleaning Performance, Versatile and Easy to Use, Durable and Reliable`,
      blink:`https://www.amazon.in/ref=nav_logo?tag=inrdeal123-21`,
   },















   

   














     
]

export default data;