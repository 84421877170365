import React from 'react'
import Navbar from '../components/NavBar'
import Footer from '../components/Footer';

import Aldo from './layout/Layout1';
import aldoContent from './content/Footwear/aldoContent';
import Nike from './layout/carouselslide1';
import NikeContent from './content/Footwear/nikeContent';
import { Helmet } from "react-helmet";
import Tabs4 from './Footwear/TabsLayout'


const Footwear = () => {
  return (
    <div>
        <div>
            <Navbar />

            <Helmet>
        <title>Shop TrendZ Footwear | Puma, Nike, Reebok, Adidas, Crocs & Luxury Brands</title>
        <meta name="description" content="Discover TrendZ Footwear for the latest in Puma, Shop For Shoes, Nike, Reebok, Adidas, Crocs, luxury footwear, and girls' footwear. Shop stylish, high-quality shoes from top brands and elevate your wardrobe with our trendy and comfortable collections. Visit https://trendz.blog/footwear for unbeatable deals and exclusive styles.Puma, Nike, Reebok, Adidas, Crocs, luxury footwear, girls' footwear, TrendZ footwear, stylish shoes, high-quality footwear, trendy footwear, comfortable shoes, top footwear brands, footwear collections, online shoe shopping, fashion footwear, exclusive shoe styles, TrendZ blog, latest shoe trends" />
        <meta name="keywords" content="Puma, Nike, Reebok, Adidas, Crocs, luxury footwear, girls' footwear, TrendZ footwear, stylish shoes, high-quality footwear, trendy footwear, comfortable shoes, top footwear brands, footwear collections, online shoe shopping, fashion footwear, exclusive shoe styles, TrendZ blog, latest shoe trends" />
      </Helmet>


        </div>

        <div className='lg:m-10'>
       
       
       
            <div className=''>
                <Tabs4 />
            </div>

            <div className=' flex flex-col lg:flex-row justify-center items-center py-5 lg:mx-32 mx-8 px-5 border bg-black rounded-xl  '>
                <img className='w-2/3 md:w-1/4' src='https://assets.turbologo.com/blog/en/2019/11/19084917/puma-logo-cover-958x575.png' alt='' />
                 <p className='mx-7 text-white'>Puma is a leading global sports brand that designs, develops, sells, and markets footwear, apparel, and accessories. For over 70 years, Puma has relentlessly pushed sport and culture forward by creating fast products for the world’s fastest athletes. Known for its innovative designs and cutting-edge technology, Puma combines performance and style in every piece.</p>
            
                   <div className=' my-3  border justify-center items-center py-3 px-16  bg-gray-400'>
                    <a href='https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen' className='text-lg inline-text font-semibold text-black text-2xl text-center  uppercase hover:text-white font-bold  '>Visit Now</a>
                   </div>
            </div>
        </div>

        <div>
            <div className='mt-10 mx-[5rem] lg:mx-[15rem]'>
                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Aldo_Group_logo.svg/2560px-Aldo_Group_logo.svg.png'  alt='aldo'/>
            </div>
            <div>
                <Aldo data={aldoContent} />
            </div>
        </div>

        <div className='mx-5 rounded-xl flex flex-col border bg-white text-black  text-center my-5'>
            <h1 className='text-3xl mt-5 font-bold'>Nike Footwear</h1>
            <img src='https://i.pinimg.com/736x/33/e6/3d/33e63d5adb0da6b303a83901c8e8463a.jpg' className='w-28 place-self-center' alt='nike logo' />
            <div>

                <Nike data={NikeContent}/>

            </div>
        </div>



        <div>
            <Footer />
        </div>
    </div>
  )
}

export default Footwear