import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Blog from '../layout/ProductList';
import SnitchContent from '../content/snitchContent'  
import SnitchTop from '../content/SnitchTop'
import SnitchBottom from '../content/SnitchBottom'
import { orange } from '@mui/material/colors';  


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const MNewArrivaltabs = () => {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  
      }  
    return (
    <div><Box sx={{ width: '100%',  }}>
    <Box sx={{ borderBottom: 0 , }}>
      <div className='flex place-content-center'>
      <Tabs value={value}  onChange={handleChange} aria-label="basic tabs example">
        <Tab  label="Best Seller" sx={{color:orange[500]}}  {...a11yProps(0)} />
        <Tab label="Top Wear" sx={{color:orange[500]}} {...a11yProps(1)} />
        <Tab label="Bottom Wear" sx={{color:orange[500]}} {...a11yProps(2)} />
      </Tabs>
      </div>
    </Box>
    <CustomTabPanel  value={value} index={0}>

        
      
      <Blog data={SnitchContent}/>  

   

      
    </CustomTabPanel>
    <CustomTabPanel value={value} index={1}>
    <Blog data = {SnitchTop} />
    </CustomTabPanel>
    <CustomTabPanel value={value} index={2}>
    <Blog data = {SnitchBottom} />
    </CustomTabPanel>
  </Box></div>
  )
}

export default MNewArrivaltabs