import React from 'react';

import { MantineProvider } from '@mantine/core';


const Blog = ({data}) => {


  return (
    <MantineProvider>
       
    <div className="bg-white">
   
      
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
        
        
        
        
          
        <div  className="mt-6 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-4 lg:grid-cols-5 xl:gap-x-8">
          
        {data.map((product, index) => (
            
            <div key={index}  className="group relative">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
                
              </div>
              <div className="mt-4 flex justify-between">

                <div>
                  <h3 className="text-sm text-gray-700">
                    
                    <a href={product.href}  >
                      
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                     
                    </a>
                    
                  </h3>
                  
                  <p className="mt-1 text-sm text-gray-500 uppercase">{product.color}</p>
                </div>
                <p className="text-sm font-medium text-gray-900">{product.price}</p>
              </div>
            </div>
        ))}
            
         
        </div>
        
        
        
      </div>
      
         
    </div>
    </MantineProvider>
    
    
    
  )
}

export default Blog