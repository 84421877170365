import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Carousel } from '@mantine/carousel';




const Cards = ({data}) => {
  return (
    <> 
     
    <div className='lg:max-w-screen-lg max-w-screen-sm md:max-w-screen-md '> 
    <Carousel 
    
    slideSize={{  sm: '20%', md: '10.333333%' }}
    slideGap={{ base: 0, sm: 'md' }}
    loop
    align="start"
    
    
  >
    {data.map((product, index) => (
      
    <Carousel.Slide key={index}>
       <div className='flex  items-center  justify-center bg-white'>
       
       <div  className='grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>


    
     
      
    <Card  sx={{ maxWidth: 240  }}>
    <CardMedia
      sx={{ height: 250, width: 240 }}
      image={product.img}
      title="green iguana"
    />
    <CardContent>
      <h6 >
      
        {product.name}
        
      </h6>
      
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small">{product.rate}</Button>
      <Button size="small"><img src={product.logo} className='rounded-s-full  ' alt={product.site} width='25px'/><a href={product.link}>Buy Now</a></Button>
      
    </CardActions>
  </Card>
  </div>
  </div>
    

  </Carousel.Slide>
  
  ))}  
   
  </Carousel>
  </div>
  
  
  
  
    
    
    </>

  )
}

export default Cards