import { useRef } from 'react';
import Autoscroll from 'embla-carousel-auto-scroll';
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';


import { BsStarFill , BsStarHalf } from 'react-icons/bs'
import { GoArrowRight } from "react-icons/go";



const Carouselslide2 = ({data}) => {
    const autoscroll = useRef(Autoscroll({ delay: 2000 }));
  return (
    <div>
    <MantineProvider withGlobalStyles withNormalizeCSS>
    

    <Carousel className='center justify-center p-3 xl:px-[8rem] gap-5  px-3  '
     mx="auto"
     sx={{ maxWidth: 320 }}
 withIndicators
 height={400}
 slideSize="20%"
 slideGap="xs"
 loop
 align="start"
 slidesToScroll={3}
 plugins={[autoscroll.current]}
                onMouseEnter={autoscroll.current.stop}
                onMouseLeave={autoscroll.current.reset}>
{data.map((article, index)=>(
    <div key={index} className=' px-3'>
 <Carousel.Slide className=' w-[12rem] h-full md:w-[15rem] border-opacity-60 rounded-xl overflow-hidden'>
   <a href={article.blink}><img className=' w-full ' src={article.thumbnail}
  alt='' /></a>
 
  <h1 className='xl:text-2xl text-center my-3 text-xl font-semibold '>{article.title.substring(0, 12)}...</h1>
            <h1 className='text-lg text-center mx-3'>{article.skincare}</h1>
            <div className='flex flex-row justify-center'>
                <BsStarFill className='text-orange-600' />
                <BsStarFill className='text-orange-600' />
                <BsStarFill className='text-orange-600' />
                <BsStarHalf className='text-orange-600'/>


            </div>
           
           
            <div className="flex lg:mt-5 gap-1 lg:gap-3 justify-center items-center rounded-lg mt-4 border bg-yellow-500 rounde-xl">
                                <img className='w-1/4 py-2 border-2 border-white  rounded-lg   ' src={article.brand} alt='' />
                                    <a href={article.blink} className='flex-inline inline-flex lg:text-xl font-semibold hover:text-orange-500 text-black  text-sm'>{article.tradeRate}</a>
                                    <a href={article.blink} className=" hover:border-2px hover:bg-orange-500 ml-4 rounded-xl  text-white  items-center" placeholder='read more' to={`/article/${article.name}`}>
                                    <GoArrowRight className='text-3xl ' /></a>
                                </div> 
   

  
 

  </Carousel.Slide>
  </div>
  ))}
  
 
  
 
 
  

  
 
 {/* ...other slides */}
</Carousel>

    </MantineProvider>
 
</div>
  )
}

export default Carouselslide2