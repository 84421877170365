import { useRef } from 'react';
import { MantineProvider, Card, Overlay, } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import classes from './mantineCss/VerticalBannerDemo.module.css';
import Autoplay from 'embla-carousel-autoplay';

const VerticalBanner = ({data}) => {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <div>
        <MantineProvider>
         <Carousel classNames={classes} 
         orientation="vertical" 
         height={200} 
         plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
      withIndicators
      >
         {data.map((article, index)=>(
      <Carousel.Slide key={index}>
      <Card radius="lg" key={index} className='h-36 lg:h-72 '>
      <a href={article.link}>
        <Overlay class={classes.overlay} opacity={0.85} zIndex={0}><img className=' w-full ' src={article.img} alt='' /></Overlay>
        </a>  
        <div className={classes.content}>
          
          <div className='flex place-content-center'>
          
    
          
          </div>
        </div>
      </Card>
      </Carousel.Slide>
         ))}
      
      {/* ...other slides */}
    </Carousel>
    </MantineProvider>
    </div>
  )
}

export default VerticalBanner