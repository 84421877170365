

const data = [
    {
        name:`TrendZ X Intro's Snitch`,
        title:`Snitch offers trendy, high-quality clothing for men, combining style and comfort for a modern wardrobe..`,
        offer:`Get Extra 15% Discount Use Coupon`,
        coupon:`TRENDZBLOG`,
        img:`https://www.snitch.co.in/cdn/shop/files/657b235705553b9102e118d69c164174_84140feb-6f71-4378-a4d6-c76b4ed89c3f.webp?v=1719232506&width=1800`,
        img1:`https://www.snitch.co.in/cdn/shop/files/0c3c77eb63a7ea8ef641a123748b47cb.webp?v=1717516527&width=1800`,
        img2:`https://www.snitch.co.in/cdn/shop/files/4MSO4515-04-324.jpg?v=1696008288&width=1800`,
        img3:`https://www.snitch.co.in/cdn/shop/files/4MSS2719-01-M25.jpg?v=1711519165&width=1800`,
        img4:`https://www.snitch.co.in/cdn/shop/files/4MSS3012-03-M35.jpg?v=1717497214&width=1800`,
        img5:`https://www.snitch.co.in/cdn/shop/files/b8feb1c90f4d9829f6fafa4e812e977c.webp?v=1719548007&width=1800`,
        img6:`https://www.snitch.co.in/cdn/shop/files/4MSS2850-05-M46.jpg?v=1708507731&width=1800`,
        link:`https://www.snitch.co.in/collections/new-drops?gad_source=1&gclid=CjwKCAjwp4m0BhBAEiwAsdc4aN2dzOqKlt5LlZScnrQIA3TPhoDu64zqJJcRP_dRlIdi3dEUHWB0uxoCCosQAvD_BwE&page=2&ref=3wSXfTh7wAmkpb&utm_campaign=VM_Performance_Max_CatchAll_21Jun%2722&utm_medium=cpc&utm_source=google`,

    }
]

export default data;