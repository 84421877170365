const data =[

    {
        img:`https://wrogn.com/cdn/shop/files/WEB-BANNER-1_8b411b90-fb61-4891-8058-ff25b4743910.jpg?v=1723702777&width=2000`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwrogn.com%2F`,

    },
    {
        img:`https://thehouseofrare.com/cdn/shop/files/rr_banner_2_1900x.jpg?v=1722857647`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fthehouseofrare.com%2Fcollections%2Frr-new-arrivals-for-men`,

    },

    {
        img:`https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/banner-images/ISRO_MEN__HOMEPAGE.jpg?format=webp&w=1500&dpr=1.5`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fmen`,

    },

    {
        img:`https://cdn.pixelspray.io/v2/black-bread-289bfa/iix9pB/wrkr/original/gasjeans/banner/379_202408160759_GAS_john_Desktop_1200x628_.jpg`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gasjeans.in%2Fsale-gas`,

    },

]

export default data