
import React from 'react'
import { useRef } from 'react';
import Autoscroll from 'embla-carousel-auto-scroll';
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';


const CarouselSlide1 = ({data}) => {
  const autoscroll = useRef(Autoscroll({ delay: 2000 }));
  return (
    <div>
      <MantineProvider withGlobalStyles withNormalizeCSS>

      <Carousel className='bg-black'
     mx="auto"
     sx={{ maxWidth:320 }}
 withIndicators
 height={450}
 slideSize="30%"
 slideGap="xs"
 loop
 align="end"
 slidesToScroll={1}
 plugins={[autoscroll.current]}
                onMouseEnter={autoscroll.current.stop}
                onMouseLeave={autoscroll.current.reset}
                >


{data.map((card,index) => (
<Carousel.Slide key={index} className='mx-3'>

       <div className='flex items-center justify-center'>
       
       <div  className=''>
       
         <div key={card}  className='group relative item-center justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow bg-white'  >
         
            <div  className='h-96 w-72   '><img src={card.thumbnail}
             alt='bnner' className='h-full w-full lg:w-96 lg:h-auto group-hover:rotate-1  group-hover:scale-125 transition-transform duration-500'/>
            </div>
         
            <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:via-black/60 to group-hover:to-black/70'>
            </div>
            <div className='absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all' >
              <h1 className='text-xl lg:text-2xl font-dmserif font-bold text-white '>{card.title}</h1>
              <p className='text-lg italic text-white mb-3 opacity-0 group-hover:opacity-100 transition-opacity'>{card.category}</p>
              <a href={card.blink} className='rounded-full shadow shadow-black/60 py-2 bg-neutral-900 px-3.5 text-sm capitalize text-white'>{card.traderate}</a>
            
            </div>
          </div>
          
        
        </div>
      </div>

      </Carousel.Slide>
      ) )}
      </Carousel>


      </MantineProvider>
    </div>
  )
}

export default CarouselSlide1