import React from 'react'
import { MantineProvider, Card, Overlay, Button, Text } from '@mantine/core';
import classes from './mantineCss/ImageActionBanner.module1.css';
import { Carousel } from '@mantine/carousel';


const Banner2 = ({data}) => {
  return (
    <MantineProvider>
       <Carousel >

       {data.map((article, index)=>(

       <Carousel.Slide>

    <Card radius="lg" key={index} className='h-36 lg:h-72 '>
        
      <Overlay class={classes.overlay} opacity={0.85} zIndex={0}><img className=' w-full ' src={article.img} alt='' /></Overlay>

      <div className={classes.content}>
        <Text size="lg" fw={700} className={classes.title}>
          
        </Text>

        <Text size="sm" className={classes.description}>
          
        </Text>
        <div className='flex place-content-center'>
        <Button className='lg:top-48 top-20'  variant="white" color="black" size="sm">
       <a href={article.link}>   Visit Now </a>
        </Button>
        </div>
      </div>
    </Card>
    </Carousel.Slide>
       ))}

    </Carousel>

    </MantineProvider>
  )
}

export default Banner2