
import { MantineProvider, Tabs } from '@mantine/core';
import Cards from '../layout/Cards';
import Jeans from '../content/Men/jeans';
import Cargo from '../content/Men/cargo';
import Pant from '../content/Men/pant';






const verticlaTabs = () => {
  
  return (
    
    <MantineProvider>
   
         <Tabs color="orange" orientation="vertical" defaultValue="Jeans">
         
      <Tabs.List>
        <Tabs.Tab fz={15} value="Jeans" >
        Jeans
        </Tabs.Tab>
        <Tabs.Tab fz={15}  value="Cargo" >
        Cargo
        </Tabs.Tab>
        <Tabs.Tab fz={15} value="Trouser"  >
        Trouser
        </Tabs.Tab>
        <Tabs.Tab fz={15} value="Pant"  >
        Pant
        </Tabs.Tab>
        <Tabs.Tab fz={15} value="Shorts"  >
          Shorts
        </Tabs.Tab>

      </Tabs.List>
      

      <Tabs.Panel value="Jeans">
        
        <Cards data={Jeans} />
      </Tabs.Panel>
      <Tabs.Panel value="Cargo">

      <Cards data={Cargo} />
    
      </Tabs.Panel>

      <Tabs.Panel value="Pant">
        <Cards data={Pant} />
      </Tabs.Panel>

      <Tabs.Panel value="settings">
        Settings tab content
      </Tabs.Panel>
    
    </Tabs>
    
    </MantineProvider>
    
  )
}

export default verticlaTabs