const data = [

    {
        name:`Korean Pants Sage Green`,
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1730888240_9278079.jpg?format=webp&w=480&dpr=1.5`,
        rate:`₹2,199`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fsolids-light-sage-green-men-pants%3Fgte%3D0`,
        logo:`/images/theshouldstore.jpg`,
        site:'The Should Store',
    },

    {
        name:`Korean Pants: Jet Black`,
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1727427587_4265364.jpg?format=webp&w=480&dpr=1.5`,
        rate:`₹2,299`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fsolids-jet-black-men-korean-pants%3Fgte%3D0`,
        logo:`/images/theshouldstore.jpg`,
        site:'The Should Store',
    },
]

export default data;