const data = [

    {
        name:`Men Mid-Rise Cotton Cargos`,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/26011114/2023/11/23/02083f08-3ae3-4730-9e59-ea550ba0dd271700734352721Trousers1.jpg`,
        rate:`₹959`,
        link:`https://myntr.it/YLerbGj`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },

    {
        name:`Men Relaxed Fit Cargo Pants`,
        img:`https://assets.ajio.com/medias/sys_master/root/20240929/QngX/66f9000ff9b8ef490b805358/-1117Wx1400H-700513937-charcoal-MODEL.jpg`,
        rate:`₹799`,
        link:`https://ajiio.in/3i7FLdj`,
        logo:`/images/ajio.png`,
        site:'Ajio',
    },
    {
        name:`Reslag Men Mid-Rise Cargos`,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/26011112/2023/11/23/d50ac38d-eb66-4aca-9f49-d969f8d3adde1700734582158ReslagMenSilver-TonedCargosTrousers1.jpg`,
        rate:`₹959`,
        link:`https://myntr.it/w4bfg7D`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },

    {
        name:`Copper Brown Relaxed Fit`,
        img:`http://www.snitch.co.in/cdn/shop/files/4MSO4631-04-3212.jpg?v=1729776081`,
        rate:`₹1,999`,
        link:`https://www.snitch.co.in/products/copper-brown-relaxed-fit-cargo-pants4mso4631-05?ref=3wSXfTh7wAmkpb&variant=45020902129826`,
        logo:`/images/snitch.png`,
        site:'Snitch',
    },

    {
        name:`Peanuts: Street Champ`,
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1716369762_2038543.jpg?format=webp&w=480&dpr=1.5`,
        rate:`₹1,999`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fpeanuts-street-champ-men-oversized-joggers%3Fgte%3D0`,
        logo:`/images/theshouldstore.jpg`,
        site:'The Should Store',
    },

    {
        name:`Solid Straight Fit Easy Wash `,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/2024/SEPTEMBER/13/crx9VTED_a10eabddf0f6499f8303fc9bc37fcfa6.jpg`,
        rate:`₹869`,
        link:`https://myntr.it/azirQZS`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },

    {
        name:`The Life Co. Men Mid-Rise`,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/23903172/2023/8/18/b62faea0-dca5-45e6-99f7-821a21efae3a1692350318817-Mens-Overdyed-Trouser-with-Snap-button--Baggy-Pocket-2051692-1.jpg`,
        rate:`₹1399`,
        link:`https://myntr.it/svp27tm`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },

    {
        name:`Men's Black Oversized Cargo`,
        img:`https://images.bewakoof.com/t1080/men-s-black-oversized-cargo-pants-609849-1727161632-2.jpg`,
        rate:`₹1,272`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-oversized-cargo-trousers-11`,
        logo:`/images/bewakoof.png`,
        site:'myntra',
    },

    {
        name:`Slim Fit Flat-Front Cargo `,
        img:`https://assets.ajio.com/medias/sys_master/root/20230418/UaD0/643ec340907deb497aed9d27/-1117Wx1400H-469484661-black-MODEL2.jpg`,
        rate:`₹1399`,
        link:`https://ajiio.in/gFkMgaI`,
        logo:`/images/ajio.png`,
        site:'Ajio',
    },
    {
        name:`Solids Olive Men Cargo Jeans`,
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1713014133_2618530.jpg?format=webp&w=480&dpr=1.5`,
        rate:`₹1,999`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fmen-cargo-jeans-olive-solids%3Fgte%3D0`,
        logo:`/images/theshouldstore.jpg`,
        site:'The Should Store',
    },
    {
        name:`Solids Rust Men Cargo Pants`,
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1720876330_3682338.jpg?format=webp&w=480&dpr=1.5`,
        rate:`₹2,199`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fmen-cargo-pants-solids-rust%3Fgte%3D0`,
        logo:`/images/theshouldstore.jpg`,
        site:'The Should Store',
    },

    

]

export default data;