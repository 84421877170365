import React from 'react'
import Navbar from '../../components/NavBar'
import Banner from '../layout/Banner2';
import mensBannerContent from '../content/mensBannerContent'
import NewArrival from '../layout/mNewArrivaltabs'
import VerticalBanner from '../layout/verticalBanner'
import VerticalBannerContent from '../content/verticalBannercontent';
import Verticaltab from './menVerticalTabs';


const Men = () => {
  return (
    <div>
        <Navbar />
        <div className='m-10 lg:mx-48 md:mx-36 '>
        <Banner data={mensBannerContent} />

        </div>

        <div>
            <NewArrival  />
        </div>

        <div className='m-10 lg:mx-48 md:mx-36'>
          <VerticalBanner data={VerticalBannerContent} />
        </div>

        <div className='lg:mx-36 md:mx-36'> 
          <Verticaltab />
        </div>

        <div>
            <NewArrival  />
        </div>

      
        
        
        
    </div>
    
  )
}

export default Men