const data=[
    {

        
        name:``,
        wear:`75%off`,
        title:`Nirvana Space boat 360`,
        saleCategory:`BestSeller`,
        mrpRate:'₹7,999',
        tradeRate:'₹1,999',
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/niravana_space_main_img_v1_3000x.jpg?v=1721368479`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW-I9MT-Fs7COIxee_vu0ZLoFH97YdMeuRLQ&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fboat-nirvana-space-wireless-earbuds-with-anc`,
        brand1: `/images/amazon.jpg`  ,
        blink1:`https://www.amazon.in/boAt-Launched-Nirvana-Space-Detection/dp/B0D4VN3S8T/ref=sr_1_1_sspa?adgrpid=150870750383&dib=eyJ2IjoiMSJ9.ZL2tGl6OBGqXICupMeQalOmWzytpglWbW-pVXV1MjRP8BFvh8jvuRwb-RbQje48Nj-3AUOq8H4Qw8SriKR4cxMwUHQb9f65kE5Nzfl07zTj0yDknVv8iblXP5PqB1dGYstuAx2fJjlJpzioykB_TJScH6xCigBG3AIWy5cYzKneg_wVSgigylSZ90eIVbxHkDhpwAw71NM_DJO0aQTSG_Akc6lwFJlaXBRgnhawjUMs.vxnMeOlTLFqaiOIc77OKu91PTHNIotgbGzBP3Esu6Gk&dib_tag=se&hvadid=669028465630&hvdev=c&hvlocphy=9303027&hvnetw=g&hvqmt=b&hvrand=6240986909493070416&hvtargid=kwd-2186439959013&hydadcr=7693_2246997&keywords=boat+nirvana+ion+anc&qid=1721923597&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&tag=inrdeal123-21`  ,

        

    },
    {

        
        name:``,
        wear:`71%off`,
        title:`Airdopes alpha DeadPool`,
        saleCategory:`BestSeller`,
        mrpRate:'₹3,490',
        tradeRate:'₹999',
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/main_img_alpha_3000x.png?v=1721649247`,
        brand: `https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png` ,
        blink: `https://fktr.in/DcHLBXv` ,
        brand1: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW-I9MT-Fs7COIxee_vu0ZLoFH97YdMeuRLQ&s`  ,
        blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fairdopes-alpha-deadpool-edition`  ,

    },

    {

        
        name:``,
        wear:`47% OFF`,
        title:`Airdopes 192 Gaming`,
        saleCategory:`BestSeller`,
        mrpRate:'₹3490',
        tradeRate:'₹1,898',
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/1_1.gif?v=1686650188`,
        brand: `https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png` ,
        blink: `https://fktr.in/Y1dR8bP` ,
        brand1: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW-I9MT-Fs7COIxee_vu0ZLoFH97YdMeuRLQ&s`  ,
        blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fboat-airdopes-192`  ,

    },

    {

        
        name:``,
        wear:`76% OFF`,
        title:`Airdopes 311 Pro Wireless`,
        saleCategory:`BestSeller`,
        mrpRate:'₹4,990',
        tradeRate:'₹1,198',
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard1_3b27e703-000f-4f72-aa1f-b81f561b49e9_1000x.png?v=1716613746`,
        brand: `/images/amazon.jpg` ,
        blink: `https://www.amazon.in/boAt-Airdopes-311-Pro-low-latency/dp/B0CZ3ZPD8B/ref=asc_df_B0CZ3ZPD8B/?tag=inrdeal123-21&linkCode=df0&hvadid=686195309225&hvpos=&hvnetw=g&hvrand=15650870782002528236&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9303027&hvtargid=pla-2308277847542&psc=1&mcid=40c17da7e16235d3a1e4596afa5127e4&gad_source=1` ,
        brand1: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW-I9MT-Fs7COIxee_vu0ZLoFH97YdMeuRLQ&s`  ,
        blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fairdopes-311-pro-wireless-earbuds`  ,

    },
    {

        
        name:``,
        wear:`60% OFF`,
        title:`Smart Ring Active Stainless `,
        saleCategory:`BestSeller`,
        mrpRate:'₹9,999',
        tradeRate:'₹3,999',
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/F_Black_3000x.png?v=1721616024`,
        brand: `/images/amazon.jpg` ,
        blink: `https://www.amazon.in/boAt-Launched-SmartRing-Stainless-Steel-Lightweight/dp/B0D4VD1CMN?tag=inrdeal123-21` ,
        brand1: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW-I9MT-Fs7COIxee_vu0ZLoFH97YdMeuRLQ&s`  ,
        blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fsmart-ring-active%3Fvariant%3D41330151784546`  ,

    },

    {

        
        name:``,
        wear:`56% OFF`,
        title:`Airdopes 161 DeadPool`,
        saleCategory:`BestSeller`,
        mrpRate:'₹2,490',
        tradeRate:'₹1,099',
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/Artboard1_cf4cf5e7-e1b7-4ec0-b4b8-6a747133e810_2250x.png?v=1695621264`,
        brand: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW-I9MT-Fs7COIxee_vu0ZLoFH97YdMeuRLQ&s` ,
        blink: `https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fairdopes-161-deadpool-edition` ,
        brand1: `https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png`  ,
        blink1:`https://fktr.in/cNCIKO9`  ,

    },

    {

        
        name:``,
        wear:`51% OFF`,
        title:`Stone 350 Hulk Edition`,
        saleCategory:`BestSeller`,
        mrpRate:'₹3,490',
        tradeRate:'₹1699',
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/2_af0208b4-7170-4caa-ba25-140d986d383c_600x.png?v=1695621545`,
        brand: `/images/amazon.jpg` ,
        blink: `https://www.amazon.in/boAt-Bluetooth-Playtime-Multi-Compatibility-Charging/dp/B0CG622ZGH?tag=inrdeal123-21` ,
        brand1: `/images/flipkart.png`  ,
        blink1:`https://fktr.in/w9nW3aL`  ,

    },

]

export default data;