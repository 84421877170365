const data =[

    {
        img:`https://www.jockey.in/cdn/shop/files/Banner-3_3.webp?v=1722346196&width=1600`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.jockey.in%2Fpages%2Fmen`,
    },

    
    {
        img:`https://brand.assets.adidas.com/image/upload/f_auto,q_auto,fl_lossy/if_w_gt_1920,w_1920/running_fw24_ub5_global_launch_mglp_banner_hero_d_ae2dd91a57.jpg`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fmen-ultraboost-running`,
    },

    {
        img:`https://assets.ajio.com/cms/AJIO/WEB/D-1.0-UHP-17082024-MainBanner-Z1-P4-netplay-dnmx-flat60.jpg`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fs%2Fflat-60-percent-off-5520-50551`,
    },

    {
        img:`https://im.uniqlo.com/global-cms/spa/resa1b2e0aa887ce248b1820b703e39aa03fr.jpg`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.uniqlo.com%2Fin%2Fen%2Fmen%2Flifewear-collection%2Fouterwear`,
    },
]


export default data;