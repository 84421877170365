const data = [

    {
        name:`Men Slim Fit Light Fade`,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/30754533/2024/9/11/53364cc5-af77-4305-a86a-d9e735e14f0a1726071466652-HIGHLANDER-Men-Slim-Fit-Light-Fade-Stretchable-Jeans-6317260-4.jpg`,
        rate:`₹869`,
        link:`https://myntr.it/2m1l8xh`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },
    {
        name:`Men Mid-Rise Danzel`,
        img:`https://assets.ajio.com/medias/sys_master/root/20240917/p9mK/66e96b0f1d763220faeec862/-1117Wx1400H-442660378-indigo-MODEL.jpg`,
        rate:`₹1172`,
        link:`https://ajiio.in/5Qg2ScK`,
        logo:`/images/ajio.png`,
        site:'Ajio',
    },
    {
        name:`Men Blue Skinny Fit `,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/10187377/2019/9/21/eca11bde-de71-46ba-a6af-97fbfdf7a3f71569065067568-WROGN-Men-Jeans-51569065065611-1.jpg`,
        rate:`₹1739`,
        link:`https://myntr.it/tyjFmp7`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },

    {
        name:`Men Black Slim Fit Mid-Rise`,
        img:`https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/10685386/2019/11/22/98d53799-2fe8-453c-bf47-c133e7d05d661574414613986-WROGN-Men-Jeans-2551574414608387-2.jpg`,
        rate:`₹1594`,
        link:`https://myntr.it/9P88Gq2`,
        logo:`/images/myntra.png`,
        site:'myntra',
    },

    {
        name:`Men Stone-Wash Tapered`,
        img:`https://assets.ajio.com/medias/sys_master/root/20240910/PqdL/66e014051d763220fad57e8b/-1117Wx1400H-469674986-blue-MODEL2.jpg`,
        rate:`₹1025`,
        link:`https://ajiio.in/Sga6YBr`,
        logo:`/images/ajio.png`,
        site:'Ajio',
    },
    

]

export default data;